<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="app">
    <div class="display">
      <div class="sharing" v-if="sharingDisplay">
        <div class="text">
          Scan this QR code to download your picture
        </div>
        <div class="qr-container">
          <img class="qr-code" alt="QR Code" :src=qrData />
        </div>
        <div class="close-container">
          <img
            src="@/assets/close.png"
            alt="Close"
            @click="() => {sharingDisplay = !sharingDisplay}"
          />
        </div>
      </div>
      <img
        v-if="this.$store.state.currentBatch"
        :src="getFileUrl(this.$store.state.currentBatch.photo_file)"
        alt="c"
      />
    </div>
    <div class="buttons">
      <img
        src="@/assets/share.png"
        alt="Share"
        @click="showShare"
      />
    </div>
    <swiper
      class="swiper"
      :slides-per-view="8"
      :freeMode="true"
      :modules="swiperModules"
    >
      <swiper-slide
        class="swiper-slide"
        v-for="(session, index) in this.$store.state.batchQueue"
        :key="session.id"
        @click="setCurrentBatch(index)"
      >
        <img
          :src="getFileUrl(session.thumbnail)"
          alt="slide"
          class="swiper-lazy"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<!-- eslint-disable import/extensions -->
<script>
import QRCode from 'qrcode';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode } from 'swiper';

import 'swiper/css';

export default {
  name: 'App',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      server_address: process.env.VUE_APP_SERVER_ADDRESS,
      socket: null,
      connected: false,
      qrData: null,
      sharingDisplay: false,
      cameraId: Number(process.env.VUE_APP_CAMERA_ID),
    };
  },
  methods: {
    setCurrentBatch(index) {
      this.$store.commit('setCurrentBatch', index);
    },
    getFileUrl(url) {
      if (url.startsWith('http')) {
        return url;
      }

      return `${this.server_address}${url}`;
    },
    showShare() {
      QRCode.toDataURL(
        `${this.server_address}?id=${this.$store.state.currentBatch.id}`,
        {
          scale: 8,
        },
      ).then((i) => {
        this.qrData = i;
        this.sharingDisplay = !this.sharingDisplay;
      });
    },
    setupSocket() {
      this.gallerySocket = new WebSocket(
        `${process.env.VUE_APP_SOCKET_ADDRESS}/ws/sharing/`,
      );

      this.gallerySocket.onopen = (e) => {
        console.log(e);

        this.connected = true;
      };

      this.gallerySocket.onclose = (e) => {
        console.log(e);

        this.connected = false;

        console.log('Waiting 5s to reconnect');

        setTimeout(() => {
          this.setupSocket();
        }, 5000);
      };

      this.gallerySocket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (data.type === 'send_initial_sessions') {
          const sessions = [];

          data.sessions.forEach((session) => {
            if (session.camera_id === this.cameraId) {
              sessions.push(session);
            }
          });

          this.$store.commit('setInitialBatches', sessions);
        }

        if (data.type === 'send_session') {
          const session = {
            id: data.id,
            input_file: data.input_file,
            photo_file: data.photo_file,
            thumbnail: data.thumbnail,
            camera_id: data.camera_id,
          };

          if (
            !this.$store.state.batchQueue.some(({ id }) => id === session.id)
            && session.camera_id === this.cameraId
          ) {
            this.$store.commit('addBatch', session);
          }
        }
      };
    },
  },
  mounted() {
    this.setupSocket();
  },
  setup() {
    return {
      swiperModules: [FreeMode],
    };
  },
};
</script>

<style>
@font-face {
  font-family: "MTVGravityGrotesk";
  src:  url("~@/assets/fonts/MTVGravityGrotesk-Book.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "MTVGravityGrotesk";
  src:  url("~@/assets/fonts/MTVGravityGrotesk-BookItalic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

body {
  background: #000;
  margin: 0;
  overscroll-behavior-y: contain;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  align-items: center;
  display: flex;
  font-family: "MTVGravityGrotesk", Helvetica, Arial, sans-serif;
  height: 100vh;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
  text-align: center;
}

.app {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vw * 1.6);
  max-width: calc(100vh * 0.625);
  position: relative;
  width: 100%;
}

.display {
  align-items: center;
  display: flex;
  height: 85%;
  justify-content: center;
  width: 100%;
}

.display img{
  height: 100%;
}

.sharing {
  background: #fffb;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.close-container {
  display: flex;
  height: 5vh;
  justify-content: center;
  align-items: center;
}

.close-container img {
  height: 75%;
}

.text {
  font-size: 5vh;
}

.qr-container {
  height: 33vh;
  margin: 12% 0;
}

.qr-container img {
  border-style: solid;
  border-width: 2px;
}

.buttons {
  align-items: center;
  display: flex;
  height: 5%;
  justify-content: center;
  width: 100%;
}

.buttons img {
  height: 75%;
}

.swiper {
  height: 10%;
  width:100%;
}

.swiper-slide img {
  width: auto;
  height: 100%;
}
</style>
