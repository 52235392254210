import { createStore } from 'vuex';

export default createStore({
  state: {
    batchQueue: [],
    currentBatch: null,
    initialBatchLoaded: false,
    // serverToken: process.env.VUE_APP_SERVER_TOKEN,
  },
  getters: {
  },
  mutations: {
    setInitialBatches(state, batches) {
      state.batchQueue = batches;
      state.initialBatchLoaded = true;
      [state.currentBatch] = batches;
    },
    addBatch(state, batch) {
      const { batchQueue } = state;
      batchQueue.push(batch);
      state.batchQueue = batchQueue;
    },
    setCurrentBatch(state, index) {
      const { batchQueue } = state;
      state.currentBatch = batchQueue[index];
    },
    clearCurrentBatch(state) {
      state.currentBatch = null;
    },
  },
  actions: {
  },
  modules: {
  },
});
